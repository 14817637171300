import { useCombobox } from 'downshift'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { tw } from 'twind'
import { content } from '@twind/content'

const Select = ({
  className,
  defaultValue,
  id,
  isFetching,
  onChange,
  options,
  value,
  ...inputProps
}) => {
  const [items, setItems] = useState([])
  const [selectedItem, setSelectedItem] = useState('')

  const {
    isOpen,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    inputValue,
    highlightedIndex,
    getItemProps,
    openMenu
  } = useCombobox({
    selectedItem,
    items,
    itemToString: item => item.label ?? item,
    onInputValueChange: ({ inputValue, selectedItem }) =>
      setItems(
        inputValue === (selectedItem?.label ?? selectedItem)
          ? options
          : options.filter(option =>
              new RegExp(inputValue).test(option.label ?? option)
            )
      ),
    onSelectedItemChange: ({ selectedItem }) => {
      setSelectedItem(selectedItem)
      if (onChange) {
        onChange({
          target: {
            id,
            value: selectedItem?.value ?? selectedItem
          }
        })
      }
    }
  })

  useEffect(() => {
    setItems(
      inputValue === (selectedItem?.label ?? selectedItem)
        ? options
        : options.filter(option =>
            new RegExp(inputValue).test(option.label ?? option)
          )
    )
  }, [inputValue, options, selectedItem])

  useEffect(() => {
    if (options.length > 0 && (defaultValue || value) && selectedItem === '') {
      setSelectedItem(
        options.find(
          option => (option.value ?? option) === (defaultValue || value)
        )
      )
    }
  }, [defaultValue, options, selectedItem, value])

  return (
    <div
      className={tw(
        'relative',
        isFetching && 'animate-pulse pointer-events-none'
      )}
    >
      <div
        {...getComboboxProps({
          className: tw`after::(absolute bg-gray-100 dark:(bg-gray-700 text-gray-500) pl-2 right-2 text-gray-400 top-1 ${content(
            '"⌄"'
          )}) relative`
        })}
      >
        <input
          {...getInputProps({
            className,
            id,
            onKeyPress: event =>
              event.key === 'Enter' && event.preventDefault(),
            onClick: () => !isOpen && openMenu(),
            onFocus: () => !isOpen && openMenu(),
            ...inputProps
          })}
        />
      </div>

      <ul
        className={`custom-shadow ${tw`absolute bg-gray-100 dark:(bg-gray-600) max-h-64 mt-1 overflow-y-auto rounded w-full z-50`}`}
        {...getMenuProps()}
      >
        {isOpen &&
          items.map((option, index) => (
            <li
              {...getItemProps({
                className: tw(
                  'cursor-pointer p-2 transition-colors',
                  highlightedIndex === index && 'bg-gray-200 dark:(bg-gray-500)'
                ),
                index,
                key: option.value ?? option
              })}
            >
              {option.label ?? option}
            </li>
          ))}
      </ul>
    </div>
  )
}

Select.propTypes = {
  className: PropTypes.string,
  isFetching: PropTypes.bool,
  options: PropTypes.array.isRequired
}

export default Select
