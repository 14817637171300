import PropTypes from 'prop-types'
import { tw } from 'twind'

const Heading2 = ({ children, ...props }) => (
  <h2 className={tw`mb-4 text-xl`} {...props}>
    {children}
  </h2>
)

Heading2.propTypes = {
  children: PropTypes.node.isRequired
}

export default Heading2
