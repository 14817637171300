import { useState } from 'react'

const useProvideAuth = () => {
  const [state, setState] = useState({
    error: null,
    isAuthenticated: false,
    isLoading: false
  })

  const login = ({ callback, ...credentials } = {}) => {
    setState(prevState => ({
      ...prevState,
      error: null,
      isLoading: true
    }))

    if (localStorage.token) {
      credentials.token = localStorage.token
    }

    fetch(`https://${localStorage.api}/_auth`, {
      body: JSON.stringify(credentials),
      headers: {
        accept: 'application/json',
        'content-type': 'application/json'
      },
      method: 'POST'
    })
      .then(res => res.json())
      .then(({ error, data }) => {
        if (error) {
          throw error
        }

        localStorage.setItem('token', data.token)

        setState(prevState => {
          const nextState = {
            ...prevState,
            isLoading: false
          }

          if (error) {
            localStorage.removeItem('token')
            nextState.error = error
          } else {
            nextState.isAuthenticated = true
          }

          return nextState
        })

        if (callback) {
          callback()
        }
      })
      .catch(error => {
        localStorage.removeItem('token')
        setState(prevState => ({
          ...prevState,
          error,
          isLoading: false
        }))
      })
  }

  const logout = callback => {
    localStorage.removeItem('token')

    setState(prevState => ({
      ...prevState,
      isAuthenticated: false
    }))

    if (callback) {
      callback()
    }
  }

  return {
    ...state,
    login,
    logout
  }
}

export default useProvideAuth
