import PropTypes from 'prop-types'
import { tw } from 'twind'
import InputNumber from 'react-number-format'
import Select from './Select'
import SelectFromService from './SelectFromService'

const Input = ({
  formatOptions,
  isClearable,
  options,
  queryParams,
  serviceKey,
  serviceName,
  type,
  ...props
}) => {
  const className = tw(
    'bg-gray-100 dark:(bg-gray-700 focus:(bg-gray-600 outline-none)) hover:(opacity-90) p-2 rounded transition-all',
    (props.disabled || props.readOnly) && 'opacity-50',
    ['checkbox', 'radio'].includes(type) ? 'mt-3' : 'block w-full'
  )

  if (type === 'number') {
    return <InputNumber className={className} {...props} />
  }

  if (type === 'select') {
    return (
      <Select
        className={className}
        options={
          isClearable
            ? [{ label: '(Alle anzeigen)', value: '' }, ...options]
            : options
        }
        {...props}
      />
    )
  }

  if (type === 'service') {
    return (
      <SelectFromService
        className={className}
        isClearable={isClearable}
        queryParams={queryParams}
        serviceKey={serviceKey}
        serviceName={serviceName}
        {...props}
      />
    )
  }

  if (type === 'textarea') {
    return <textarea className={`${className} ${tw`h-24`}`} {...props} />
  }

  return <input className={className} type={type} {...props} />
}

Input.propTypes = {
  formatOptions: PropTypes.object,
  isClearable: PropTypes.bool,
  options: PropTypes.array,
  queryParams: PropTypes.object,
  serviceKey: PropTypes.string,
  serviceName: PropTypes.string,
  type: PropTypes.string
}

export default Input
