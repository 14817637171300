import { useHistory } from 'react-router-dom'
import { tw } from 'twind'
import useAuth from '../hooks/useAuth'
import IconDoorClosedFill from './Icons/DoorClosedFill'

const LogOutButton = () => {
  const history = useHistory()
  const auth = useAuth()

  return (
    <button
      className={tw`block dark:text-gray-500 focus:outline-none hover:(scale-110) text-gray-400 transition-transform`}
      onClick={() => auth.logout(() => history.push('/'))}
      title='Ausloggen'
    >
      <IconDoorClosedFill height={18} width={18} />
    </button>
  )
}

export default LogOutButton
