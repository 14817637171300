import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import PrivateRoute from './components/PrivateRoute.js'
import AppShell from './containers/AppShell'
import ErrorBoundary from './containers/ErrorBoundary'
import Login from './containers/Login'
import { ProvideAuth } from './context/auth'

const App = () => (
  <ErrorBoundary>
    <ProvideAuth>
      <Router>
        <Switch>
          <Route path='/login'>
            <Login />
          </Route>

          <PrivateRoute path='/'>
            <AppShell />
          </PrivateRoute>
        </Switch>
      </Router>
    </ProvideAuth>
  </ErrorBoundary>
)

export default App
