import { useState } from 'react'
import { tw } from 'twind'
import IconMoon from './Icons/Moon'
import IconSun from './Icons/Sun'
import { getThemePref, toggleThemeClass } from '../utils'

const iconAttrs = {
  height: 18,
  width: 18
}

const ThemeToggle = () => {
  const [currentTheme, setCurrentTheme] = useState(
    localStorage.theme || getThemePref()
  )

  const setTheme = theme => {
    localStorage.setItem('theme', theme)
    toggleThemeClass(theme)
    setCurrentTheme(theme)
  }

  const handleTheme = ({ currentTarget: { name } }) => {
    setTheme(name === 'light' ? 'dark' : 'light')
  }

  const buttonProps = {
    onClick: handleTheme,
    title: 'Farbthema umschalten'
  }

  const className = tw`absolute block dark:text-gray-500 focus:outline-none text-gray-400 transform transition-transform`

  return (
    <div
      className={tw`hover:(scale-110) overflow-hidden relative transition-transform`}
      style={{ height: 18, width: 18 }}
    >
      <button
        className={`${className} ${tw(
          currentTheme !== 'light' && 'scale-0 translate-y-8'
        )}`}
        name='light'
        {...buttonProps}
      >
        <IconMoon {...iconAttrs} />
      </button>

      <button
        className={`${className} ${tw(
          currentTheme !== 'dark' && 'scale-0 translate-y-8'
        )}`}
        name='dark'
        {...buttonProps}
      >
        <IconSun {...iconAttrs} />
      </button>
    </div>
  )
}

export default ThemeToggle
