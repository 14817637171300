import { tw } from 'twind'

const Logo = () => (
  <>
    <span className={tw`dark:text-white sm:hidden`}>
      <span className='text-primary'>B</span>RM
    </span>
    <span className={tw`dark:text-white hidden sm:block`}>
      <span className='text-primary'>Beyond</span> Relationship Marketing
    </span>
  </>
)

export default Logo
