import React from 'react'
import ReactDOM from 'react-dom'
import { setup } from 'twind'
import * as colors from 'twind/colors'
import App from './App'
import { getThemePref, toggleThemeClass } from './utils'

setup({
  darkMode: 'class',
  preflight: preflight => ({
    ...preflight,
    ':root': {
      '--primary-hsl': '21deg 83% 52%'
    },
    body: {
      ...preflight.body,
      fontFamily: 'Gidole-Regular'
    },
    '@font-face': {
      fontFamily: 'Gidole-Regular',
      src: 'url("/fonts/Gidole-Regular.woff2") format("woff2")',
      fontWeight: 'normal',
      fontStyle: 'normal'
    },
    '.bg-primary': {
      backgroundColor: 'hsl(var(--primary-hsl))'
    },
    '.border-primary': {
      borderColor: 'hsl(var(--primary-hsl))'
    },
    '.custom-shadow': {
      boxShadow: 'hsla(var(--primary-hsl) / 0.33) 0.25rem 0.25rem'
    },
    '.custom-shadow-xl': {
      boxShadow:
        'hsla(var(--primary-hsl) / 0.33) 0.25rem 0.25rem, hsla(var(--primary-hsl) / 0.33) 8px 8px'
    },
    '.text-primary': {
      color: 'hsl(var(--primary-hsl))'
    }
  }),
  theme: { colors }
})

toggleThemeClass(localStorage.theme || getThemePref())

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)
