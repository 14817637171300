import PropTypes from 'prop-types'
import { useEffect, useRef } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { tw } from 'twind'
import nav from '../data/nav.json'

const getClassNames = () =>
  tw(
    'block',
    'px-2',
    'rounded',
    'transition-colors',
    'hover:(bg-gray-200 dark:bg-gray-600)'
  )

const Nav = ({ isOpen, setIsOpen }) => {
  const { pathname } = useLocation()

  const prevPathname = useRef(pathname)

  useEffect(() => {
    if (isOpen && pathname !== prevPathname.current) {
      setIsOpen(false)
    }
    prevPathname.current = pathname
  }, [isOpen, pathname, setIsOpen])

  return (
    <nav>
      <ul>
        {nav.map(parent => (
          <li className={tw`font-bold mb-2`} key={parent.name}>
            {parent.pathname ? (
              <Link
                className={`${
                  parent.pathname === pathname ? 'text-primary' : ''
                } ${getClassNames()}`}
                to={parent.pathname}
              >
                {parent.name}
              </Link>
            ) : (
              <span className='px-2'>{parent.name}</span>
            )}

            {parent.children && (
              <ul className={tw`font-normal ml-4 mt-2`}>
                {parent.children.map(child => (
                  <li key={child.pathname}>
                    <Link
                      className={`${
                        child.pathname === pathname ? 'text-primary' : ''
                      } ${getClassNames()}`}
                      to={child.pathname}
                    >
                      {child.name}
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </nav>
  )
}

Nav.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired
}

export default Nav
