import React from 'react'
import { tw } from 'twind'
import Button from '../components/Button'
import Error from '../components/Error'
import H2 from '../components/Heading2'
import ModalPage from '../components/ModalPage'
import P from '../components/Paragraph'

class ErrorBoundary extends React.Component {
  constructor (props) {
    super(props)
    this.state = { error: null }
  }

  static getDerivedStateFromError (error) {
    return { error: error }
  }

  componentDidCatch (error, errorInfo) {
    console.error(error, errorInfo)
  }

  render () {
    const { error } = this.state

    if (error) {
      return (
        <ModalPage title='Fehler'>
          <H2>Es ist ein Fehler aufgetreten.</H2>

          <P>
            <Error>
              s kalsdjfkl sdjfkl jasdklf jkalsdjf kljasdklf sd{error}
            </Error>
          </P>

          <div className={tw`mt-4`}>
            <Button onClick={() => window.location.reload()}>
              App neu starten
            </Button>
          </div>
        </ModalPage>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
