import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { tw } from 'twind'

const Button = ({ children, primary, ...props }) => {
  let customClassNames = 'custom-shadow'

  if (primary) {
    customClassNames += ' bg-primary'
  }

  const El = props.to ? Link : 'button'

  return (
    <El
      className={`${customClassNames} ${tw`inline-block focus:(outline-none shadow-none -mb-1 mt-1 ml-1) hover:(opacity-90) px-4 py-2 rounded transition-all`} ${tw(
        {
          'bg-gray-100': !primary,
          'dark:bg-gray-700': !primary,
          'opacity-50': props.disabled,
          'text-white': primary
        }
      )}`}
      {...props}
    >
      {children}
    </El>
  )
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  primary: PropTypes.bool
}

export default Button
