import PropTypes from 'prop-types'
import schema from '../data/schema'
import useApi from '../hooks/useTestApi'
import Error from './Error'
import Select from './Select'

const SelectFromService = ({
  isClearable,
  queryParams = {},
  serviceKey,
  serviceName,
  ...props
}) => {
  const { data, error, isFetching } = useApi({
    endpoint: serviceName,
    query: {
      order: schema[serviceName].defaultOrder,
      ...queryParams
    },
    searchKeys: schema[serviceName].searchKeys
  })

  if (error) {
    return <Error>{error.message}</Error>
  }

  const options = data.map(item => ({
    label: item[schema[serviceName].labelKey],
    value: item[serviceKey]
  }))

  return (
    <Select
      isFetching={isFetching}
      options={
        isClearable
          ? [{ label: '(Alle anzeigen)', value: '' }, ...options]
          : options
      }
      {...props}
    />
  )
}

SelectFromService.propTypes = {
  isClearable: PropTypes.bool,
  queryParams: PropTypes.object,
  serviceKey: PropTypes.string.isRequired,
  serviceName: PropTypes.string.isRequired
}

export default SelectFromService
