import { useHistory, useRouteMatch } from 'react-router-dom'
import { tw } from 'twind'
import Button from '../components/Button'
import DocTitle from '../components/DocTitle'
import H1 from '../components/Heading1'

const NotFound = () => {
  const history = useHistory()
  const { url } = useRouteMatch()
  const title = 'Inhalt nicht gefunden'

  return (
    <DocTitle title={title}>
      <div
        className={`custom-shadow ${tw`bg-white dark:(bg-gray-800 text-white) p-4`}`}
      >
        <H1>{title}</H1>
        Unter der Adresse{' '}
        <code
          className={tw`break-all bg-gray-200 dark:(bg-gray-600) px-1 rounded`}
        >
          {url}
        </code>{' '}
        konnten keine Inhalte gefunden werden.
        <div className={tw`mt-4 whitespace-nowrap`}>
          <Button onClick={() => history.goBack()}>Zurück</Button>
        </div>
      </div>
    </DocTitle>
  )
}

export default NotFound
