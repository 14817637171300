import { tw } from 'twind'
import H1 from './Heading1'
import { LoadingMsg } from './Loading'

const Fallback = () => (
  <div
    className={`custom-shadow ${tw`bg-white dark:(bg-gray-800 text-white) p-4`}`}
  >
    <div className={tw`animate-pulse`}>
      <H1>
        <LoadingMsg />
      </H1>
    </div>
  </div>
)

export default Fallback
