import { lazy, Suspense, useState } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { Route, Switch } from 'react-router-dom'
import { tw } from 'twind'
import { name, version } from '../../package.json'
import Fallback from '../components/Fallback'
import Header from '../components/Header'
import Nav from '../components/Nav'
import NotFound from './NotFound'

const Dashboard = lazy(() => import('./Dashboard'))
const Editor = lazy(() => import('./Editor'))
const Index = lazy(() => import('./Index'))
const Settings = lazy(() => import('./Settings'))

const crudRoutes = [
  'aufzeichnungen',
  'buchungen',
  'debitoren',
  'inklusiva',
  'kontaktadressen',
  'kundenkonten',
  'leistungen',
  'leistungsgruppen',
  'mailclasses',
  'mtas',
  'nutzer',
  'pakete',
  'pdf_auftraege',
  'posten',
  'rechnungen',
  'rechnungsempfaenger',
  'staffelpreise',
  'systeme',
  'texte'
]

const queryClient = new QueryClient()

const AppShell = () => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <QueryClientProvider client={queryClient}>
      <div
        className={tw`bg-gray-100 dark:(bg-black text-white) min-h-screen text-gray-800`}
      >
        <Header toggleMenu={() => setIsOpen(prevState => !prevState)} />

        <div className={tw`flex`}>
          <div
            className={`${isOpen ? 'custom-shadow-xl' : ''} ${tw(
              'bg-white dark:(bg-gray-800 text-white) fixed lg:(block shadow-none static translate-x-0 w-3/12) overflow-y-auto px-3 py-2 transition-transform z-10',
              !isOpen && '-translate-x-72'
            )}`}
            style={{ minHeight: 'calc(100vh - 2.75rem)', minWidth: '16rem' }}
          >
            <Nav isOpen={isOpen} setIsOpen={setIsOpen} />
          </div>

          <div className={tw`overflow-x-auto p-4 w-full`}>
            <Suspense fallback={<Fallback />}>
              <Switch>
                <Route path='/' exact>
                  <Dashboard />
                </Route>

                {crudRoutes.map(name => (
                  <Route path={`/${name}`} exact key={`index-${name}`}>
                    <Index endpoint={name} />
                  </Route>
                ))}

                {crudRoutes.map(name => (
                  <Route path={`/${name}/:id`} exact key={`editor-${name}`}>
                    <Editor endpoint={name} />
                  </Route>
                ))}

                <Route path='/vorgaben' exact>
                  <Settings endpoint='vorgaben' />
                </Route>

                <Route path='*'>
                  <NotFound />
                </Route>
              </Switch>
            </Suspense>

            <p className={tw`m-4 text-gray-500 text-right text-sm`}>
              {name} v{version} → {localStorage.api}
            </p>
          </div>
        </div>
      </div>

      <ReactQueryDevtools position='bottom-right' />
    </QueryClientProvider>
  )
}

export default AppShell
