import PropTypes from 'prop-types'
import { tw } from 'twind'
import Logo from './Logo'
import LogOutButton from './LogOutButton'
import MenuButton from './MenuButton'
import ThemeToggle from './ThemeToggle'

const Header = ({ toggleMenu }) => {
  return (
    <div
      className={`${tw`bg-white dark:(bg-gray-800) flex items-center justify-between px-3 py-2`}`}
    >
      <div className={tw`flex items-center -mx-2`}>
        <div className={tw`mx-2 lg:hidden`}>
          <MenuButton toggleMenu={toggleMenu} />
        </div>
        <div className={tw`mx-2 text-xl`}>
          <Logo />
        </div>
      </div>

      <div className={tw`flex items-center -mx-3`}>
        <div className={tw`mx-3`}>
          <ThemeToggle />
        </div>
        <div className={tw`mx-3`}>
          <LogOutButton />
        </div>
      </div>
    </div>
  )
}

Header.propTypes = {
  toggleMenu: PropTypes.func.isRequired
}

export default Header
