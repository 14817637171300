import PropTypes from 'prop-types'
import { tw } from 'twind'

const Heading1 = ({ children, ...props }) => (
  <h1 className={tw`mb-4 text-3xl`} {...props}>
    {children}
  </h1>
)

Heading1.propTypes = {
  children: PropTypes.node.isRequired
}

export default Heading1
