import PropTypes from 'prop-types'
import { tw } from 'twind'
import IconList from './Icons/List'

const MenuButton = ({ toggleMenu }) => (
  <button
    className={tw`block dark:text-gray-500 focus:outline-none text-gray-400`}
    onClick={toggleMenu}
    title='Ausloggen'
  >
    <IconList height={26} width={26} />
  </button>
)

MenuButton.propTypes = {
  toggleMenu: PropTypes.func.isRequired
}

export default MenuButton
