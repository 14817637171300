import PropTypes from 'prop-types'
import { tw } from 'twind'

const Error = ({ children }) => (
  <code className={tw`text-red-500`}>Fehler: {children}</code>
)

Error.propTypes = {
  children: PropTypes.node
}

export default Error
