import PropTypes from 'prop-types'
import { Redirect, Route } from 'react-router-dom'
import Loading from '../components/Loading'
import useAuth from '../hooks/useAuth'

const PrivateRoute = ({ children, ...rest }) => {
  const { isAuthenticated, isLoading } = useAuth()

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoading ? (
          <Loading />
        ) : isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location }
            }}
          />
        )
      }
    />
  )
}

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired
}

export default PrivateRoute
