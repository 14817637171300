import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags'

const DocTitle = ({ children, title, ...props }) => (
  <>
    <MetaTags>
      <title>
        {`${
          document.head.querySelector('meta[name="description"]').content
        } | ${title}`}
      </title>
    </MetaTags>

    {children}
  </>
)

DocTitle.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired
}

export default DocTitle
