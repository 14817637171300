import { tw } from 'twind'

export const LoadingMsg = () => 'Lade…'

const Loading = () => (
  <div
    className={tw`bg-gray-100 dark:(bg-black) flex items-center justify-center min-h-screen w-screen text-gray-500 text-xl`}
  >
    <LoadingMsg />
  </div>
)

export default Loading
