import { useCallback, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { tw } from 'twind'
import Button from '../components/Button'
import DocTitle from '../components/DocTitle'
import Input from '../components/Input'
import H1 from '../components/Heading1'
import Label from '../components/Label'
import Loading, { LoadingMsg } from '../components/Loading'
import Logo from '../components/Logo'
import ModalPage from '../components/ModalPage'
import ThemeToggle from '../components/ThemeToggle'
import useAuth from '../hooks/useAuth'

const Login = () => {
  const history = useHistory()
  const location = useLocation()
  const { error, isAuthenticated, isLoading, login } = useAuth()

  const { from } = location.state || { from: { pathname: '/' } }

  const handleLogin = useCallback(
    credentials => {
      login({
        ...credentials,
        callback: () => history.replace(from)
      })
    },
    [from, history, login]
  )

  const handleSubmit = event => {
    event.preventDefault()
    const { nutzername, passwort } = event.currentTarget.elements
    handleLogin({ nutzername: nutzername.value, passwort: passwort.value })
  }

  useEffect(() => {
    if (localStorage.token && !isAuthenticated && !isLoading) {
      login({ callback: () => history.replace(from) })
    }
  }, [from, history, isAuthenticated, isLoading, login])

  return (
    <DocTitle title='Login'>
      {localStorage.token ? (
        <Loading />
      ) : (
        <ModalPage>
          <div className={tw`absolute p-2 py-1 right-0 top-0`}>
            <ThemeToggle />
          </div>

          <H1>
            <Logo />
          </H1>

          <form
            className={tw({ 'opacity-75': isLoading })}
            onSubmit={handleSubmit}
          >
            <div className={tw`mb-2`}>
              <Label htmlFor='nutzername'>Nutzername</Label>
              <Input
                autoComplete='username'
                autoFocus
                id='nutzername'
                required
              />
            </div>

            <div className={tw`mb-2`}>
              <Label htmlFor='passwort'>Passwort</Label>
              <Input
                autoComplete='current-password'
                id='passwort'
                required
                type='password'
              />
            </div>

            <div className={tw`mb-2`}>
              <Label htmlFor='api'>API</Label>
              <Input
                id='api'
                options={[
                  'billapi-test.beyondrm.com/webapi',
                  'postgrest-billing.preview.mundpropaganda.net'
                ]}
                onChange={e =>
                  localStorage.setItem(e.target.id, e.target.value)
                }
                required
                type='select'
              />
            </div>

            <div className={tw({ 'text-red-500': !!error })}>
              {error ? (
                `Fehler: ${error}`
              ) : isLoading ? (
                <LoadingMsg />
              ) : (
                'Bitte loggen Sie sich ein!'
              )}
            </div>

            <div className={tw`mt-4`}>
              <Button disabled={isLoading} primary type='submit'>
                Login
              </Button>
            </div>
          </form>
        </ModalPage>
      )}
    </DocTitle>
  )
}

export default Login
