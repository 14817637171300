import { useQuery } from 'react-query'
import schema from '../data/schema'

const getSearchParam = (name, key, value) => {
  const { type } = schema[name].columns.find(field => field.key === key)

  switch (type) {
    case 'number':
      return !isNaN(value) && `${key}.eq.${value}`

    case 'text':
      return `${key}.ilike.*${value}*`

    default:
      return `${key}.eq.${value}`
  }
}

export const client = ({
  endpoint = '',
  join,
  params = {},
  queryString = ''
}) => {
  const { headers = {}, ...options } = params
  const controller = new AbortController()

  if (options.body) {
    headers['content-type'] = 'application/json'
    headers['prefer'] = 'return=representation'
  }

  const p = fetch(`https://${localStorage.api}/${endpoint}?${queryString}`, {
    ...options,
    headers: {
      accept: 'application/json',
      authorization: `Bearer ${localStorage.token}`,
      ...headers
    },
    signal: controller.signal
  }).then(async res => {
    if (res.status === 204) {
      return
    }

    let json = await res.json()

    if (!Array.isArray(json)) {
      throw new Error(json.message)
    }

    const joins =
      join &&
      schema[endpoint].columns
        .filter(col => col.type === 'service')
        .map(col => col.key)

    if (json.length > 0 && joins?.length > 0) {
      await Promise.all(
        joins.map(key => {
          const { serviceKey, serviceName } = schema[endpoint].columns.find(
            col => col.key === key
          )

          const values = [
            ...json.reduce((set, item) => {
              const value = item[key]
              if (value !== null && value !== undefined) {
                set.add(item[key])
              }
              return set
            }, new Set())
          ]

          let queryString = `select=${serviceKey},${schema[serviceName].labelKey}`

          if (values.length > 0) {
            queryString += `&or=(${values
              .map(val => `${serviceKey}.eq.${val}`)
              .join(',')})`
          }

          return client({ endpoint: serviceName, queryString }).then(data => ({
            [key]: data.reduce(
              (acc, item) => ({
                ...acc,
                [item.id]: item[schema[serviceName].labelKey]
              }),
              {}
            )
          }))
        })
      ).then(res => {
        const joinData = res.reduce((acc, data) => ({ ...acc, ...data }), {})

        json = json.map(item => {
          joins.forEach(key => {
            const label = joinData[key][item[key]]
            if (label) {
              item[key] = label
            }
          })
          return item
        })
      })
    }

    return json
  })

  p.cancel = () => controller.abort()

  return p
}

const useApi = ({ endpoint = '', join, query = {}, searchKeys = [] }) => {
  const { searchterm, ...queryParams } = query

  if (searchterm) {
    queryParams.or = `(${searchKeys
      .map(key => getSearchParam(endpoint, key, searchterm))
      .filter(a => a)
      .join()})`
  }

  const queryString = new URLSearchParams(queryParams).toString()

  return useQuery(
    [endpoint, queryString],
    () =>
      query.id === 'eq.0'
        ? Promise.resolve([])
        : client({ endpoint, join, queryString }),
    { initialData: [], keepPreviousData: true }
  )
}

export default useApi
