import schema from './data/schema'

// FIXME: handle theme events with React's context API

const originalSetItem = window.localStorage.setItem

window.localStorage.setItem = function (key, value) {
  if (key === 'theme') {
    const event = new Event('themeChange')
    event.value = value
    window.document.dispatchEvent(event)
  }

  originalSetItem.apply(this, arguments)
}

export const formatDateTime = datetime => {
  const date = typeof datetime === 'string' ? new Date(datetime) : datetime
  return new Intl.DateTimeFormat('de-DE', {
    dateStyle: 'short',
    timeStyle: 'short'
  }).format(date)
}

export const formatNum = (value, options = {}) =>
  new Intl.NumberFormat('de-DE', options).format(value)

export const getColumns = endpoint =>
  schema[endpoint].columns.reduce(
    (cols, col) =>
      col.confirm
        ? [
            ...cols,
            col,
            {
              ...col,
              key: `${col.key}-confirm`,
              label: `${col.label} bestätigen`
            }
          ]
        : [...cols, col],
    []
  )

export const getISOLocaleString = (input, omitSeconds = true) => {
  const date =
    typeof input === 'object'
      ? input
      : input !== undefined
      ? new Date(input)
      : new Date()

  const [day, month, year, hour, minute, seconds] = date
    .toLocaleString()
    .match(/(\d{1,2})\.(\d{1,2})\.(\d{4}), (\d{1,2}):(\d{2}):(\d{2})/)
    .slice(1)

  return `${year}-${month.padStart(2, '0')}-${day.padStart(
    2,
    '0'
  )}T${hour.padStart('0')}:${minute}${omitSeconds ? '' : `:${seconds}`}`
}

export const getNumber = value =>
  Number(
    value
      .toString()
      .split(',')
      .map(a => a.replace(/\D/g, ''))
      .join('.')
  )

export const getThemePref = () =>
  window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'

export const parseQueryParams = (endpoint, search) => {
  const { limit, offset, order, searchterm, ...other } = Object.fromEntries(
    new URLSearchParams(search)
  )

  const nextState = {
    limit: Number(limit ?? 20),
    offset: Number(offset ?? 0),
    order: order ?? schema[endpoint].defaultOrder ?? 'id.asc',
    searchterm
  }

  Object.entries(other).forEach(([key, value]) => {
    if (!schema[endpoint].columns.find(col => col.key === key)) {
      return
    }

    if (value) {
      nextState[key] = value
    }
  })

  return nextState
}

export const persistQueryParams = params => {
  const searchParams = Object.fromEntries(
    Object.entries(params).filter(
      ([key, value]) => ![null, undefined, ''].includes(value)
    )
  )

  window.history.replaceState(
    {},
    undefined,
    `${window.location.pathname}?${new URLSearchParams(
      searchParams
    ).toString()}`
  )
}

export const toggleThemeClass = theme => {
  if (theme === 'dark') {
    document.documentElement.classList.add('dark')
  } else {
    document.documentElement.classList.remove('dark')
  }
}
