import PropTypes from 'prop-types'
import { tw } from 'twind'

const Paragraph = ({ children, className }) => (
  <p className={`${tw`mb-2`} ${className}`}>{children}</p>
)

Paragraph.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
}

export default Paragraph
