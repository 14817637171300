import PropTypes from 'prop-types'
import { tw } from 'twind'

const Label = ({ children, ...props }) => (
  <label className={tw`block dark:(text-gray-400) text-gray-500`} {...props}>
    {children}
  </label>
)

Label.propTypes = {
  children: PropTypes.node.isRequired
}

export default Label
