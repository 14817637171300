import PropTypes from 'prop-types'
import { tw } from 'twind'

const ModalPage = ({ children, title }) => (
  <div
    className={tw`bg-gray-100 dark:(bg-black text-gray-200) flex items-center justify-center p-8 min-h-screen w-screen text-gray-800 transition-opacity`}
  >
    <div
      className={`custom-shadow-xl ${tw`bg-white dark:(bg-gray-800) drop-shadow-xl w-full p-8 rounded sm:(w-8/12) md:(w-7/12) lg:(w-1/2) xl:(w-4/12)`}`}
    >
      {children}
    </div>
  </div>
)

ModalPage.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string
}

export default ModalPage
