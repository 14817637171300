const List = props => (
  <svg
    width={16}
    height={16}
    fill='currentColor'
    viewBox='0 0 16 16'
    {...props}
  >
    <path
      fillRule='evenodd'
      d='M2.5 12a.5.5 0 01.5-.5h10a.5.5 0 010 1H3a.5.5 0 01-.5-.5zm0-4a.5.5 0 01.5-.5h10a.5.5 0 010 1H3a.5.5 0 01-.5-.5zm0-4a.5.5 0 01.5-.5h10a.5.5 0 010 1H3a.5.5 0 01-.5-.5z'
    />
  </svg>
)

export default List
